import React from "react"

import style from "./layout.module.scss"

const Footer = ({ author, vertical }) => {

  return (
    <footer className={vertical ? style.verticalFooter: style.horizontalFooter}>
      <p>Created by {author}, © {new Date().getFullYear()}</p>
    </footer>
  )
}

export { Footer }
