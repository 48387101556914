import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import style from "./layout.module.scss"

import ThemeContext from '../context/ThemeContext'

import DarkMode from '../images/react-svg/dark-light.svg';
import LightMode from '../images/react-svg/light-dark.svg';
import Logo from '../images/react-svg/sider.is-logo-min-gray.svg';

const TopBar = ({ siteTitle }) => {

  return (
    <ThemeContext.Consumer>
      {theme => (
        <header className={style.verticalHeader}>
          <div className={style.verticalTitle}>
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <nav>
            <ul className={style.verticalNavList}>
              <li><Link className={style.verticalNavItem} activeClassName={style.verticalNavItemActive} to="/">Home</Link></li>
              <li><Link className={style.verticalNavItem} activeClassName={style.verticalNavItemActive} partiallyActive={true} to="/blog">Blog</Link></li>
              <li><Link className={style.verticalNavItem} activeClassName={style.verticalNavItemActive} to="/projects">Projects</Link></li>
              <li><Link className={style.verticalNavItem} activeClassName={style.verticalNavItemActive} to="/about">About</Link></li>
              <li>
                <button className={style.verticalDarkSwitcher} onClick={theme.toggleDark}>
                  {theme.dark ? <LightMode /> : <DarkMode />}
                </button>
              </li>
            </ul>
          </nav>
        </header>
      )}
    </ThemeContext.Consumer>
  )
}

TopBar.propTypes = {
  siteTitle: PropTypes.string,
}

TopBar.defaultProps = {
  siteTitle: ``,
}

const SideBar = ({ siteTitle }) => {

  return (
    <ThemeContext.Consumer>
      {theme => (
        <div className={style.horizontalSidebar}>
        <nav>
          <div className={style.horizontalTitle}>
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <ul className={style.horizontalNavList}>
              <li><Link className={style.horizontalNavItem} activeClassName={style.horizontalNavItemActive} to="/">Home</Link></li>
              <li><Link className={style.horizontalNavItem} activeClassName={style.horizontalNavItemActive} partiallyActive={true} to="/blog">Blog</Link></li>
              <li><Link className={style.horizontalNavItem} activeClassName={style.horizontalNavItemActive} to="/projects">Projects</Link></li>
              <li><Link className={style.horizontalNavItem} activeClassName={style.horizontalNavItemActive} to="/about">About</Link></li>
              <li className={theme.dark ? style.horizontalNavPaddingDark : style.horizontalNavPaddingLight}><br/></li>
              <li>
                <button className={style.horizontalDarkSwitcher} onClick={theme.toggleDark}>
                  {theme.dark ? <LightMode /> : <DarkMode />}
                </button>
              </li>
          </ul> 
        </nav>
      </div>
      )}
    </ThemeContext.Consumer>
  )
}

SideBar.propTypes = {
  siteTitle: PropTypes.string,
}

SideBar.defaultProps = {
  siteTitle: ``,
}

export { TopBar, SideBar }
