/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { TopBar, SideBar } from "./menu"
import { Footer } from "./footer"

// import 'gatsby-remark-vscode/styles.css'; // See: https://www.gatsbyjs.com/plugins/gatsby-remark-vscode/#styles
import "./layout.css"
import style from "./layout.module.scss"

import ThemeContext from '../context/ThemeContext'
import { useViewport } from '../context/ViewportContext'

function isPortrait (width, height) {
  if (width == null || height == null) {
    return true;
  }
  return width < height;
}

const Layout = ({ children }) => {
  
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          author {
            name
          }
        }
      }
    }
  `)

  const siteTitle = data.site.siteMetadata?.title || `Kyriakos`
  const author = data.site.siteMetadata?.author.name || `Kyriakos Sideris`

  const { width, height } = useViewport();
  const [vertical, setVertical] = React.useState(false);
  React.useEffect(() => {
    setVertical(isPortrait(width, height));
  }, [width, height])
  // console.log(`layout.js -> handleWindowResize() -> dimensions: ${JSON.stringify(dimensions, (k, v) => v === undefined ? null : v)}`)
  
  if (vertical) {
    return (
      <ThemeContext.Consumer>
        {theme => (
          <div className={theme.dark ? style.dark : style.light}>
            <div className={style.verticalContainer}>
              <TopBar siteTitle={siteTitle} />
              {children}
              <Footer author={author} vertical={vertical}/>
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  } else {
    return (
      <ThemeContext.Consumer>
        {theme => (
          <div className={theme.dark ? style.dark : style.light}>
            <div className={style.horizontalWrapper}>
              <SideBar siteTitle={siteTitle} />
              <div className={style.horizontalMainContent}>
                <div className={style.horizontalMainWrapper}>
                  {children}
                </div>
                <Footer author={author} vertical={vertical}/>
              </div>
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
